.article {
  position: relative;
  z-index: 9;

  &__top {
    padding: 85px 0;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: $white;
    margin-bottom: 85px;

    @include mq(md) {
      padding: 40px 0;
    }

    .breadcrumbs {
      a,
      p {
        color: rgba($white, .45);
        display: flex;
        gap: 10px;
        transition: all .3s;

        &:hover {
          color: $third;
        }
      }

      .item {
        &:after {
          display: block;
          content: '>';
          width: 6px;
          height: 12px;
        }

        &:last-child {
          color: $white;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  &__author {
    padding: 30px 0;
    border-top: 1px solid rgba($white, .15);
    border-bottom: 1px solid rgba($white, .15);

    p {
      font-size: 20px;
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
      margin-bottom: 5px;
    }

    .desc {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 0;
    }
  }

  &__topImage {
    position: absolute;
    height: 350px;
    width: auto;
    bottom: 0;
    right: 7%;

    @include mq(md) {
      display: none;
    }
  }

  &__background {
    background-color: $third;
    padding: 35px;
    border-radius: 10px;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 35px;
    font-size: 16px;

    @include mq(md) {
      padding: 25px;
    }

    .title {
      font-size: 20px;
    }

    .pismiennictwo {
      font-size: 12px;
      line-break: anywhere;
    }
  }

  &__spis {
    h2 {
      margin: 0;
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;
    }
  }

  &__links {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    a {
      transition: all .2s;
      margin-bottom: 4px;
      padding: 18px 0;
      border-bottom: 1px solid #B6C8D1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        color: $secondary;
        text-decoration: underline;

        &:after {
          text-decoration: none;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      &:after {
        padding: 0 10px;
        display: block;
        content: '>';
        color: $white;
        font-size: 22px;
        font-weight: 500;
        background-color: #31AC48;
        border-radius: 50%;
      }
    }
  }

  &__content {
    padding-bottom: 30px;

    h2 {
      font-size: 28px;
      line-height: 32px;
    }

    h3 {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    ul,
    ol {
      li {
        margin-bottom: 15px;
      }
    }

    ol {
      color: rgba($black, .66);
    }

    .gray {
      color: rgba($black, .66);
    }

    img {
      margin: 40px 0;
    }
  }

  &__small {
    font-size: 14px;
    color: rgba($black, .4);
  }

  .btn {
    margin: 40px 0;
  }

  &__sticky {
    height: 33%;

    img {
      position: sticky;
      top: 80px;
    }
  }
}