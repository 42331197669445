.pwz {
    min-height: 100vh;
    position: relative;
    background: $secondary;
  font-family: Poppins, sans-serif;

  @include mq(md) {
    min-height: 100vh;
  }
  
    &__container {
      background: $white;
      padding: 30px;
      color: $secondary;
      width: fit-content;
      text-align: center;
      border-radius: 20px;
      max-width: 700px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  
      @include mq(md) {
        padding: 25px;
        max-width: 95%;
      }

      h2{
        font-size: 32px;
        font-weight: 600;
      }
    }
  
    &__logo {
      width: 150px;
      height: 75px;
      margin-bottom: 15px;
    }
  
    &__text {
      font-size: 16px;
      color: $secondary;
      line-height: 24px;
      margin-bottom: 20px;
  
      @include mq(md) {
        font-size: 18px;
        line-height: 21px;
      }
    }
  
    &__npwz {
      font-size: 16px;
      font-weight: 400;
  
      @include mq(md) {
        margin: 0 auto;
      }
    }
  
    &__input {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 15px;
      margin-bottom: 15px;
  
      input {
        width: 55px;
        height: 67px;
        outline: none;
        color: $secondary;
        font-size: 34px;
        line-height: 34px;
        font-weight: 700;
        border: none;
        text-align: center;
        background: $third;
        border-radius: 10px;
  
        @include mq(md) {
          font-size: 24px;
          line-height: 28px;
          width: 30px;
          padding: 0;
          border-radius: 0;
        }
  
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
  
        &[type=number] {
          -moz-appearance: textfield;
        }
      }
    }
    &__imdoc{
      margin-top: 30px;
      margin-bottom: 30px;
      background: rgba(255,255,255,.2);
      padding: 10px;
      text-align: center;

      label{
        display: flex;
        align-items: center;
        justify-content: center;

        input{
          margin-right: 10px;
          width: 21px;
          height: 21px;
        }
      }
    }
    &__buttons{
      display: flex;
      justify-content: center;
      gap: 10px;

      button:first-child{
        &:hover{
          padding-left: 37px;
        }
      }

      a{
        border: 1px solid $secondary;
        color: $secondary;

        &:hover{
          background: $secondary;
          color: $white;
        }
      }
    }
  
    form {
      width: fit-content;
      margin: 0 auto;
  
      @include mq(md) {
        gap: 7px;
      }
    }

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: $third;
    margin: 0 15px 0 0;
    font: inherit;
    color: $primary;
    min-width: 25px;
    min-height: 25px;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    line-height: 18px;
    padding-left: 2px;

    &::before {
      content: "✔";
      width: 0.95em;
      height: 0.95em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $third;
    }

    &:checked::before {
      transform: scale(1);
    }
  }

  .form__group {
    width: 70%;
    margin: 0 auto;

    .form__before {
      &:before {
        display: none;
      }
    }

    input {
      border-radius: 10px;
      padding: 20px;
      font-size: 19px;
      line-height: 19px;
    }
  }
}