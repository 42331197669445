.footer {
    //margin-top: 120px;
    background-color: $white;
    padding: 60px 0;
    //border-top: solid 1px $gray-100;
    position: relative;

    @include mq(md) {
        //margin-top: 50px;
        overflow: hidden;
        padding: 30px 0 40px;
    }

    &__link {
        color: $secondary;
        text-decoration: underline;

        &:hover {
            color: $primary;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        line-height: 15px;
        color: #7A7A7A;

        @include mq(md) {
            flex-direction: column;
        }

        a {
            color: $secondary;
            font-size: 15px;
            line-height: 15px;
        }

        p {
            margin-bottom: 0;
            font-size: 15px;
            line-height: 15px;
        }
    }

    &__info {
        display: flex;
        flex-direction: row;
        gap: 25px;
        align-items: center;

        @include mq(md) {
            flex-direction: column;
            align-items: flex-start;
        }

        span {
            display: flex;
            align-items: center;
            gap: 15px;
        }
    }

    &__copyright {
        margin-right: 100px;
        display: flex;
        align-items: center;

        @include mq(xxl, min) {
            margin-right: 0;
        }

        @include mq(md) {
            margin-top: 20px;
            margin-right: 0;
        }
    }

    &__brand {
        text-align: center;
        @include mq(md) {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-bottom: 1px solid rgba($white, .08);
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }

    &__ml {
        color: #43629F;
        font-size: 17px;
        margin-top: 25px;

        @include mq(md) {
            margin-top: 15px;
        }
    }

    &__contact {
        color: $secondary;
        font-size: 21px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include mq(md) {
            margin-top: 20px;
            z-index: 9;
        }

        a {
            color: $secondary;
            font-size: 16px;
            
            &.btn{
                color: $black;
                background-color: #fff;
                border-radius: 29px;
                cursor: pointer;
            }
        }

        p {
            margin-bottom: 0;
        }

        img {
            margin-right: 15px;
        }

        &--first {
            padding-left: 40px;
            border-left: 1px solid rgba($white, .08);
            margin-left: 10px;

            @include mq(md) {
                padding-left: unset;
                border-left: none;
                margin-left: unset;
            }
        }

        &--last {
            padding-right: 40px;
            border-right: 1px solid rgba($white, .08);
            margin-right: 10px;

            @include mq(md) {
                padding-right: unset;
                border-right: none;
                margin-right: unset;
            }
        }
    }

    &__adamed {
        position: absolute;
        right: 0;
        bottom: 30px;
        z-index: 0;
        background-color: $secondary;
        padding: 10px 15px 10px 30px;
        border-radius: 40px 0 0 40px;

        @include mq(md) {
            bottom: 75px;
        }

        img {
            height: 60px;
        }
    }

    &__sil {
        margin-top: 40px;
        padding-top: 40px;
        color: $black;
        font-size: 14px;
        line-height: 16px;
        min-height: 170px;

        p {
            font-size: 15px;
            line-height: 20px;
            color: rgba($black, .35);
        }

        a {
            font-size: 15px;
            line-height: 20px;
            color: rgba($white, .35);
            text-decoration: underline;
        }
    }
}