// Import
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Base variables
$wp-admin-h: 32px;
$wp-admin-mobile-h: 46px;
$navbar-h: 0;

// Color system
$white: #fff;
$black: #000;

$primary: #31AC48;
$secondary: #11366A;
$third: #EDF8FE;
$red: #DE0C1A;
$yellow: #FFF850;
$green: #79A72B;
$light: $white;
$dark: $black;

// Options
$enable-smooth-scroll: false;

// Body
$body-color: $black;

// Components
$border-radius: 5px;

// Typography
$font-family-base: 'BloggerSans', sans-serif;

// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    wp: 782px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1224px,
        xxl: 1424px
);
