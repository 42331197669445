.quiz {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  position: relative;
  z-index: 9;
  //background-image: url("../../dist/img/check-bg.webp");

  @include mq(md) {
    margin-top: 20px;
  }

  &__content {
    width: 100%;
    margin: 0 auto;
    padding: 40px;
    background-color: $third;
    border-radius: 20px;

    @include mq(md) {
      width: 100%;
      padding: 20px;
    }
  }

  &__header {
    position: relative;
    padding-bottom: 20px;
    text-align: center;

    @include mq(md) {
      flex-direction: column;
      align-items: center;
    }

    p {
      margin-bottom: 0;
      font-size: 22px;
      font-weight: 300;
      color: $secondary;

      @include mq(md) {
        font-size: 18px;
      }
    }
  }

  &__topText {
    font-size: 24px !important;

    @include mq(md) {
      font-size: 21px !important;
      margin-bottom: 5px !important;
    }
  }

  &__time {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 20px;
    color: #B5C7D1 !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;

    img {
      margin-right: 8px;
      height: 25px;
      width: 25px;
    }
  }

  &__body {

    h2 {
      text-align: center;
      font-size: 28px;
      line-height: 34px;
      margin: 0 auto 30px;
      width: 90%;

      @include mq(md) {
        font-size: 21px;
        line-height: 24px;
      }
    }
  }

  &__answers {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  &__answer {
    width: 100%;
    background-color: $white;
    border: 1px solid #ECEDEF;
    font-size: 21px;
    line-height: 22px;
    font-weight: 300;
    position: relative;
    cursor: pointer;
    border-radius: 50px;
    font-family: 'Poppins', sans-serif;

    @include mq(md) {
      font-size: 16px;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      left: 0;
      cursor: pointer;
    }

    input:hover ~ label {
      background-color: #224485;
      color: $white;
    }

    input:checked ~ label {
      background-color: #224485;
      color: $white;
    }

    label {
      padding: 16px 47px;
      width: 100%;
      height: 100%;
      transition: all .4s;
      cursor: pointer;
      background-color: $white;
      color: $black;
      border-radius: 50px;

      @include mq(md) {
        padding: 16px 32px;
      }
    }
  }

  &__progress {
    height: 3px;
    width: 100%;
    background-color: rgba($black, .22);
    margin-top: 30px;
    position: relative;

    &--0 {
      &:before {
        display: block;
        content: '';
        width: 15%;
        height: 3px;
        background-color: #0e2568;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &--1 {
      &:before {
        display: block;
        content: '';
        width: 30%;
        height: 3px;
        background-color: #0e2568;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &--2 {
      &:before {
        display: block;
        content: '';
        width: 45%;
        height: 3px;
        background-color: #0e2568;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &--3 {
      &:before {
        display: block;
        content: '';
        width: 60%;
        height: 3px;
        background-color: #0e2568;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &--4 {
      &:before {
        display: block;
        content: '';
        width: 75%;
        height: 3px;
        background-color: #0e2568;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &--5 {
      &:before {
        display: block;
        content: '';
        width: 100%;
        height: 3px;
        background-color: #0e2568;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 15px;

    @include mq(md) {
      gap: 10px;
    }

    p {
      margin-bottom: 0;
      font-size: 19px;
      text-align: center;
      font-family: 'Poppins', sans-serif;

      @include mq(md) {
        font-size: 16px;
      }

      span {
        color: red;
      }
    }

    button {
      min-width: 150px;
      height: 48px;

      @include mq(md) {
        min-width: unset;
      }
    }
  }

  &__green {
    color: $green;
  }

  &__finish {
    padding-top: 40px;
    padding-bottom: 40px;
    color: $black;

    h1 {
      margin-bottom: 10px;
      font-weight: 700;
    }

    h3 {
      margin-top: 40px;
    }
  }

  &__info {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: rgba($black, .6);
    margin-bottom: 25px;
  }

  &__checkbox {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 20px;

    a {
      text-decoration: underline;
    }

    label {
      padding: 18px;
      background-color: #EDF8FE;
      display: flex;
      align-items: flex-start;
      gap: 15px;

      p {
        margin-bottom: 0;
        font-size: 17px;
        color: $black;
        font-family: 'Poppins', sans-serif;
      }

      &:first-child {
        font-weight: 600;
      }

      input {
        border: 1px solid #C7C7C7;
        border-radius: 0 !important;
      }
    }
  }

  &__sticky {
    position: absolute;
    top: 0;
    right: 0;
    width: 48%;
    height: 202.8vh;
    z-index: 0;

    @include mq(xxl, min) {
      height: 150.5vh;
    }

    @include mq(md) {
      position: unset;
      height: unset;
      width: 100%;
    }
  }

  &__finishImage {
    position: absolute;
    top: 170px;
    right: 0;
    z-index: -1;
    width: 95%;
    height: auto;
    object-fit: contain;

    @include mq(md) {
      margin-top: 50px;
    }

    @include mq(xxl, min) {
      width: 95%;
    }
  }

  &__more {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 16px;
    text-align: center;
    color: rgba($black, .4);
  }
}