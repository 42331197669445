.main {
  height: 92vh;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
  position: relative;

  @include mq(md) {
    margin-top: auto;
    height: auto;
    padding: 50px 0;
    background-image: none !important;
   }

  &__textBox {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 44%;
    color: $white;

    @include mq(xxl, min) {
      width: 31%;
      top: 50%;
    }

    @include mq(md) {
      position: unset;
      top: unset;
      left: unset;
      transform: unset;
      width: 100%;
    }
    

    h1 {
      width: 90%;
      font-size: 3rem;
      line-height: 45px;
      color: $black;
      margin-bottom: 25px;
      
      @include mq(md) {
        font-size: 32px;
       line-height: 36px;
      }
    }
  }

  &__topText {
    font-size: 1.57rem;
    font-weight: 300;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 7px;
  }

  &__desc {
    color: $black;
    font-size: 28px;
    line-height: 32px;
    width: 75%;

    @include mq(md) {
      width: 100%;
      font-size: 21px;
      line-height: 25px;
    }
  }

  &__awards {
    position: absolute;
    bottom: 10%;
    right: 180px;
    width: 45%;

    @include mq(xxl, min) {
      right: 18%;
      bottom: 15%;
    }

    @include mq(md) {
      right: 5%;
      width: 90%;
      bottom: 10%;
      display: none;
    }
  }
  &__prizes{
    padding-bottom: 20px;
    display: flex;
    gap: 30px;
    align-items: center;
    margin-left: -10px;

    @include mq(md) {
      justify-content: space-between;

      img {
        width: 45%;
      }
    }
  }
  &__buttons {
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: -10px;

    @include mq(md) {
      margin-left: 0;
    }
  }
}

.home {
  &__rules-container{
    position: relative;
    background-color: $third;
    padding: 50px;
    border-radius: 10px;

    @include mq(md) {
      padding: 30px 25px;
    }

    & .first_box{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & img.doctor{
      position: absolute;
      width: 37%;
      right: 0;

      @media only screen and (min-width: 1400px) and (max-width: 4000px) {
        width: 28%;
      }
    }

    &--big {
      padding: 50px 30px 30px 38px;

      @include mq(md) {
        padding: 35px 25px 25px 25px;
      }
    }
  }

  &__rules {
    &--article {
      z-index: 1;
      background-size: contain;
      background-position: right center;
      background-repeat: no-repeat;

      .home__medicineContent div {
        padding-bottom: 0;
      }
    }
  }

  &__medicine{
    position: relative;

    @include mq(md) {
      margin-bottom: 50px;
    }

    h2{
      font-size: 2.625rem;
      line-height: 45px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 25px;

      @include mq(md) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p{
      font-size: 1.5rem;
      line-height: 30px;
      padding-bottom: 40px;
      color: #9C9C9C;
    }
    ul{
      padding-bottom: 30px;
    }
    ul li{
      font-size: 1.3rem;
      line-height: 30px;
      color: #9C9C9C;
      list-style: none;
      margin-bottom: 17px;
    }
    ul li::before {
      content: "\2022";
      color: #E50C1B;
      font-weight: bold;
      display: inline-block; 
      width: 1em;
      margin-left: -1em;
    }
  }

  &__medicineLogo {
    margin-bottom: 35px;
    width: auto;
    height: 47px;
  }

  &__medicineImage {
    height: 100%;
    width: auto;
    object-fit: cover;
    object-position: center;
    border-radius: 0 0 11px 11px;
  }

  &__medicineContent {
    display: flex;
    position: relative;

    @include mq(md) {
      position: unset;
      flex-direction: column;
    }

    &--right {
      img {
        &:first-of-type {
          margin-right: 100px;

          @include mq(md) {
            margin-right: 0;
            margin-bottom: 25px;
          }
        }
      }

      .home {
        &__boxItems {
          .item {
            &:nth-child(2) {
              img {
                width: 28px;
                height: 28px;
              }
            }

            &:nth-child(1) {
              img {
                height: 40px;
              }
            }

            &:last-of-type {
              img {
                height: 30px;
              }
            }
          }
        }
      }
    }

    &--article {

      .home__boxHeader {
        color: $primary;
      }

      p {
        &:last-of-type {
          width: 50%;

          @include mq(md) {
            width: 100%;
          }
        }
      }
    }

    img {
      height: 475px;
      width: auto;
      object-position: center;
      object-fit: contain;

      @include mq(md) {
        height: 220px;
      }
    }

    .primary {
      color: $primary;
    }

    .secondary {
      color: $secondary;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 40px;
    }

    p {
      font-size: 18px;
      line-height: 26px;
      font-family: Poppins, sans-serif;
      color: $black;
      width: 70%;

      @include mq(xxl, min) {
        width: 55%;
      }

      @include mq(md) {
        width: 100%;
      }
    }
  }

  &__boxHeader {
    font-size: 20px !important;
    line-height: 26px !important;
    padding-bottom: 0 !important;
    margin-bottom: 17px !important;
    text-transform: uppercase;
  }

  &__boxItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 0 !important;

    .item {
      padding-bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 17px;

      p {
        margin-bottom: 0;
        padding-bottom: 0;
        width: 100%;
      }

      img {
        height: 30px;
        width: auto;
        min-width: 22px;
        margin-right: 0;
      }

      &:nth-child(2) {
        img {
          width: 28px;
          height: 28px;
        }
      }

      &:last-of-type {
        img {
          height: 40px;
        }
      }
    }
  }

  &__medicineDawki {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 30px;

    @include mq(md) {
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      margin-right: 0;
      gap: 10px;
    }

    &--right {
      position: relative;
      left: 35%;

      @include mq(xxl, min) {
        left: 30%;
      }

      @include mq(md) {
        left: 0;
      }
    }

    p {
      font-size: 16px;
      font-family: Poppins, sans-serif;
      color: #C4C4C4;
      padding-bottom: 0;
      margin-bottom: 0;

      @include mq(md) {
        &:first-of-type {
          width: 100%;
        }
      }
    }

    .dawka {
      font-size: 15px;
      line-height: 15px;
      padding: 15px 34px;
      border: 2px solid #EFEFF0;
      border-radius: 27px;

      @include mq(md) {
        padding: 15px 20px;
      }
    }
  }

  &__rules {
    padding: 0 0 60px;
    position: relative;
    z-index: 999;

    &--first {
      margin-top: -40px;
    }
  }

  &_rule {
    height: 100%;
    position: relative;

    @include mq(md) {
      min-height: 100%;
      padding: 85px 0;
    }

    p {
      font-size: 24px;
      font-weight: 600;
      line-height: 23px;
      position: absolute;
      color: #fff;
      margin-bottom: 0;
      top: 46%;
      left: 0;
      transform: translate(0, -50%);

      @include mq(md) {
        font-size: 20px;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }

    img {
      margin-bottom: 10px;
      width: 37px;
      height: auto;
      object-position: center;
      object-fit: contain;

      @include mq(md) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

  }

  &__ruleItems {
    display: flex;
    gap: 30px;

    @include mq(md) {
      flex-direction: column;
      gap: 15px;
      margin: 30px 0;
    }
  }

  &__ruleItem {
    padding: 23px;
    position: relative;
    background-color: $primary;
    border-radius: 10px;
    flex: 1 1 31%;
    height: 233px;

    @include mq(md) {
      min-height: 200px;
    }
  }

  &__rulesNumber {
    font-size: 125px;
    line-height: 120px;
    font-weight: 600;
    color: rgba($third, 1);
    z-index: 0;
    position: absolute;
    bottom: -16%;
    right: -7%;

    @include mq(md) {
      right: -6%;
    }
  }

  &__rulesButtons {
    margin-top: 20px;
  }

  &__banner {
    margin-top: 90px;

    @include mq(md) {
      margin-top: 50px;
    }

    .banner {
      background-image: url("../../dist/img/banner.webp");
      height: 500px;
      padding: 0 46px;
      color: $white;
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @include mq(md) {
        height: auto;
        padding: 30px;
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
      }

      &__content {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        @include mq(md) {
          position: unset;
          top: unset;
          transform: unset;
        }

        h3 {
          margin-bottom: 15px;
          width: 75%;
          font-size: 48px;
          font-family: 'Besley', sans-serif;

          @include mq(md) {
            font-size: 32px;
          }
        }

        p {
          color: rgba($white, .6);
          font-size: 25px;
          line-height: 27px;
          font-family: 'Jost', sans-serif;

          @include mq(md) {
            font-size: 21px;
            line-height: 24px;
            margin-bottom: 35px;
          }
        }
      }

      &__app {
        position: absolute;
        top: 0;
        right: 0;
        max-height: 500px;

        @include mq(md) {
          position: unset;
        }
      }

      &__download {
        display: flex;
        flex-direction: row;
        gap: 15px;

        img {
          height: 55px;
          width: auto;

          @include mq(md) {
            height: 45px;
          }
        }
      }
    }
  }

  &__background {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;

    @include mq(md) {
      display: none;
    }
  }
}