.answers {
  padding: 0 0 40px;
  position: relative;
  z-index: 9;

  @include mq(md){
    margin-top: auto;
    padding: 0 0 40px;
  }

  &__header {
    text-align: center;

    h1 {
      font-size: 42px;
      font-weight: 600;
      margin-bottom: 25px;
      color: $black;
      line-height: 48px;

      @include mq(md) {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }

  &__total {
    margin-top: 25px;
    font-size: 19px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: $primary;

    @include mq(md) {
      font-size: 18px;
      margin-top: 10px;
    }
  }

  &__info {
    font-size: 22px;
    font-family: 'Jost', sans-serif;
    margin-top: 40px;
    margin-bottom: 0;
  }

  &__single {
    background-color: $third;
    margin-top: 40px;
    padding: 30px;
    border-radius: 20px;

    & p{
      font-size: 18px;
      font-family: 'Poppins', sans-serif;
    }
  }

  &__question {
    font-size: 17px!important;
    font-weight: 600;
    color: #002E94;
    font-family: 'Poppins', sans-serif;
  }

  &__check {
    text-align: left;

    p {
      margin-bottom: .5rem !important;
    }

    .correct {
      color: #31AC48;
      font-weight: 600;
    }

    .incorrect {
      color: #C41718;
      font-weight: 600;
    }

    strong {
      font-size: 25px;
    }
  }
}