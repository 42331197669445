/* poppins-100 - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/poppins-v20-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/poppins-v20-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/poppins-v20-latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/poppins-v20-latin-ext_latin-100.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-200 - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/poppins-v20-latin-ext_latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/poppins-v20-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/poppins-v20-latin-ext_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-200.woff') format('woff'), /* Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/poppins-v20-latin-ext_latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-300 - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/poppins-v20-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/poppins-v20-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/poppins-v20-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/poppins-v20-latin-ext_latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/poppins-v20-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/poppins-v20-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/poppins-v20-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/poppins-v20-latin-ext_latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/poppins-v20-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/poppins-v20-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/poppins-v20-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/poppins-v20-latin-ext_latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/poppins-v20-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/poppins-v20-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/poppins-v20-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/poppins-v20-latin-ext_latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/poppins-v20-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/poppins-v20-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/poppins-v20-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/poppins-v20-latin-ext_latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-800 - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/poppins-v20-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/poppins-v20-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/poppins-v20-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/poppins-v20-latin-ext_latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-900 - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/poppins-v20-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/poppins-v20-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/poppins-v20-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/poppins-v20-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/poppins-v20-latin-ext_latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'BloggerSans';
  src: url('../fonts/BloggerSans.eot');
  src: url('../fonts/BloggerSans.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BloggerSans.woff') format('woff'),
  url('../fonts/BloggerSans.ttf') format('truetype'),
  url('../fonts/BloggerSans.svg#BloggerSans') format('svg');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'BloggerSans';
  src: url('../fonts/BloggerSans-Light.eot');
  src: url('../fonts/BloggerSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BloggerSans-Light.woff') format('woff'),
  url('../fonts/BloggerSans-Light.ttf') format('truetype'),
  url('../fonts/BloggerSans-Light.svg#BloggerSans-Light') format('svg');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'BloggerSans';
  src: url('../fonts/BloggerSans-Medium.eot');
  src: url('../fonts/BloggerSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BloggerSans-Medium.woff') format('woff'),
  url('../fonts/BloggerSans-Medium.ttf') format('truetype'),
  url('../fonts/BloggerSans-Medium.svg#BloggerSans-Medium') format('svg');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'BloggerSans';
  src: url('../fonts/BloggerSans-Bold.eot');
  src: url('../fonts/BloggerSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BloggerSans-Bold.woff') format('woff'),
  url('../fonts/BloggerSans-Bold.ttf') format('truetype'),
  url('../fonts/BloggerSans-Bold.svg#BloggerSans-Bold') format('svg');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}